@import 'styles/variables.scss';

.card {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 .3rem 1rem rgba(0, 0, 0, 0.2);
    margin: 1rem;

    &.linen {
        background-color: $theme-linen;
    }
}

.header {
    border-bottom: 1px solid #ccc;
    position: relative;
    display: flex;
    margin: 2rem;
    margin-bottom: 0;
    max-width: 100%;
    justify-content: flex-start;
}

.headerText {
    font-size: 2.5rem;
    font-weight: 600;
    padding: 1.5rem 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.body {
    position: relative;
    display: flex;
    margin-top: 0;
    max-height: 90%;
    max-width: 100%;
    padding: 1rem;
    justify-content: center;
    overflow: hidden;
    transition: max-height 0.8s ease-in-out, padding 0.3s;
    height: fit-content;
}

.collapsed {
    .body {
        max-height: 0;
        transition: max-height 0.8s cubic-bezier(0, 1, 0, 1), padding 0.3s;
        padding: 0 1rem;
    }
}

.collapseBar {
    cursor: pointer;
    height: 4rem;
    border-top: 1px solid #ccc;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 0 2rem;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    transition: .5s;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(255,255,255, 1), rgba(255,255,255,.9), rgba(255,255,255, 1));
        z-index: -1;
      }
    &:hover {
        background: #000;
    }
}

.arrow {
    border: solid black;
    height: 0;
    width: 0;
    border-width: 0 .5rem .5rem 0;
    display: inline-block;
    padding: .4rem;
    transition: .7s;
}

.upArrow {
    @extend .arrow;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
  
.downArrow {
    @extend .arrow;
    transform: rotate(-135deg) scale(-1);
    -webkit-transform: rotate(-135deg) scale(-1);
}