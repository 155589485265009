@import 'styles/variables.scss';

.playerCard {
    color: var(--theme-primary-text);
    font-family: $header-font-family;
    font-size: 2rem;
    letter-spacing: 1px;
    height: 4.5rem;

    margin-left: 2rem;
    margin-right: 2rem;

    max-width: calc(100% - 4rem);
    min-width: 0;

    padding-top: 1rem;
    padding-bottom: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all 0.25s ease-in-out;

    &.faded {
        background: linear-gradient(90deg, rgba(var(--theme-shade),0) 0%, rgba(var(--theme-shade),0.15) 5%, rgba(var(--theme-shade),0.15) 95%, rgba(var(--theme-shade),0) 100%);
        opacity: 0.7;
    }
}

.name {
    width: 18rem;
    position: relative;
    overflow: hidden;
    flex-shrink: 2;
    flex-grow: 1;
}

.details {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 11rem;
    margin-left: 1rem;
    margin-right: 1rem;

    color: var(--theme-secondary-text);

    .position {
        margin-left: .5rem;
        background-color: rgba(var(--theme-shade-dark), 0.3);
        border-radius: .5rem;
        padding: .5rem 0;
        width: 5rem;

        display: flex;
        justify-content: center;
    }
}

.value {
    flex: 0;
}

.headshotContainer {
    align-items: center;
    border: 1px solid var(--theme-secondary-text);
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0 .25rem 1rem;
    display: flex;
    height: 4rem;
    justify-content: center;
    margin-right: 1rem;
    width: 4rem;
    overflow: hidden;
    flex-shrink: 0;

    img {
        height: 100%;
    }
}

.skeletonContainer {
    flex: 1;
}

.lastName {
    font-weight: bold;
}

.rightmostBlock {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    width: 18.5rem;
    flex-shrink: 0;

    svg {
        flex-shrink: 0;
    }
}

.addRemoveButton {
    background-color: rgba(var(--theme-shade), 0.3);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: .5rem 1rem;
    transition: all 0.1s ease-in-out;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: rgba(var(--theme-shade), 0.6);
        box-shadow: unset;
    }
}

.giveGetButton,
.giveGetRemoveButton {
    font-size: 1.75rem;
}