@import 'styles/variables.scss';

.slidePanelWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;

    pointer-events: none;

    & > * {
        pointer-events: auto;
    }
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.slidePanel {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    position: relative;

    .closeButton {
        background-color: unset;
        border: unset;
        color: $color-gray-6;
        cursor: pointer;
        font-size: 5rem;
        position: absolute;
        top: 0;
        right: 0;
    
        height: 48px;
        width: 48px;
        padding: 0;
    }

    &.linen {
        background-color: $color-brown-2;

        .closeButton {
            color: $color-brown-7;
        }
    }
}