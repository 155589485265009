@import 'styles/variables.scss';

$skew: 10deg;

.buttonRow {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        background-color: unset;
        border: .5rem solid var(--theme-primary-text);
        color: var(--theme-primary-text);
        font-family: $header-font-family;
        font-size: 3rem;
        padding: 1.5rem 2rem;
        transform: skew(-$skew);
        transition: all .2s ease-in-out;
        white-space: nowrap;

        &.small {
            font-size: 2.5rem;
            padding: 1rem 1.5rem;
        }

        &.large {
            font-size: 4rem;
            padding: 2rem 2.5rem;
        }

        &.dark {
            color: var(--theme-contrast-text);
            border-color: var(--theme-contrast-text);
        }

        &.menu {
            color: var(--theme-menu-text);
            border-color: var(--theme-menu-text);
        }

        cursor: pointer;

        &:hover:before {
            content: '';
            background-color: rgba(var(--theme-shade-dark),0.2);
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }

        &:first-child{
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
        }

        &:last-child{
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }

        &.active {
            background-color: var(--theme-primary-text);
            color: var(--theme-contrast-text);

            &.dark {
                background-color: var(--theme-contrast-text);
                color: var(--theme-primary-text);
            }

            &.menu {
                background-color: var(--theme-menu-text);
                color: var(--theme-menu-contrast-text);
            }
        }

        .rowButtonText {
            transform: skew($skew)
        }
    }
}