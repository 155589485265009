@import 'styles/variables.scss';

.section {
    max-width: 100%;
    height: calc(100% - 5rem);
    overflow: hidden;
    position: relative;
    width: $page-width;
}

.aboutCard {
    height: calc(100% - 2rem);
}

.body {
    font-size: 2rem;
    overflow: auto;
    max-height: calc(100% - 25rem);

    ul {
        padding-left: 4.5rem;
    }
}

.dataSourceLogo {
    height: 4rem;
    margin-top: .75rem;
    margin-right: 1rem;
}

.artCreditsList {
    margin: 1rem 0;
}

.closeButton {
    background: unset;
    border: unset;
    color: black;
    text-decoration: none;
    font-size: 4.5rem;

    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1;
}
