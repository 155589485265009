@import 'styles/variables.scss';

.topBar {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    width: 12rem;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    background-color: $color-brown-8;
    background-size: 20rem;
    z-index: 2;
    width: 100%;
}

.leftAlign {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}

.rightAlign {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
    margin-right: 1rem;
    height: 100%;
}

.menuIcon {
    margin-left: 2rem;
}

.logo {
    margin: .5rem 2rem 0;
}

.location {
    color: white;
    font-family: $header-font-family;
    font-size: 3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 2rem;
}

.helpIcon, .settingsIcon {
    margin: 0;
    padding: 0 1rem;
    background-color: unset;
    border: unset;
    height: 100%;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
}

.sourceLinkIcon {
    display: flex;
    flex-direction: column;
    height: 4rem;
    justify-content: center;
    align-items: center;
    margin-left: .5rem;
    width: 4rem;
    color: $color-brown-8;
}

.settingsList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    margin: 0 2rem;
    padding: 0 2rem 4rem;


    width: calc(100% - 8rem);

    .settingsRow {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }

        &.vertical {
            flex-direction: column;
        }
    }

    .settingsLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        color: var(--theme-menu-text);
        font-size: 2.5rem;
        font-weight: 500;
    }
}

.navLinks {
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
    gap: 3rem;
    width: 100%;
    position: relative;

    a {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $color-brown-7;
        font-size: 3rem;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
        padding: .5rem 4rem;
        width: 100%;
        transition: .3s;

        &.active {
            color: $color-brown-7;
        }
    }
}