@import 'styles/variables.scss';

$skew: 10deg;

.playerSelectorWrapper {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    position: absolute;
    top: 0;
    z-index: 2;
}

.playerSelector {
    transition: max-height 0.2s ease-in-out, width 0.2s ease-in-out;
    height: fit-content;
    max-height: 5rem;
    overflow: hidden;
    width: 35rem;
    margin-top: 1.5rem;
    background-color: var(--theme-input-bg);
    border: 1px solid var(--theme-input-text);
    border-radius: 2.5rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    &.open {
        border: unset;
        border-radius: 0;
        border-bottom-left-radius: 3rem;
        border-bottom-right-radius: 3rem;
        border-bottom: 1px solid var(--theme-input-text);
        box-shadow: rgba(var(--theme-shade-dark), 0.35) 0px 5px 15px;
        max-height: 50rem;
        margin-top: 0;
        padding-top: 1.5rem;
        width: 100%;
        
        .closeButton, .filterButton {
            display: block;
        }

        .filters {
            display: flex;
        }

        .searchBar {
            width: 47rem;
            border: 1px solid var(--theme-input-text);
            margin-bottom: 1rem;
        }
    }
}

.searchBar {
    height: 5rem;
    width: 35rem;
    transition: all 0.2s ease-in-out;

    background-color: unset;
    border-radius: 2.5rem;

    &:focus-within {
        outline: 2px solid var(--theme-input-text);
    }

    .searchBarInput {
        color: var(--theme-input-text);
        height: 100%;
        margin-left: 2rem;
        background: unset;
        border: unset;

        &:focus {
            outline: none;
        }
    }
}

.filterButton {
    background: unset;
    border: unset;
    color: var(--theme-input-text);
    display: none;
    position: absolute;
    padding: 0 1rem;
    top: 2.25rem;
    left: 1rem;

    .filterButtonText {
        position: absolute;
        top: .25rem;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        color: var(--theme-input-bg);
        font-size: 1.75rem;
        font-weight: bolder;
    }

    & > svg {
        fill: var(--theme-input-bg);
        transition: all 0.25s ease-in-out;
    }

    &.filtersApplied > svg {
        fill: var(--theme-primary-text);
    }
}

.filters {
    display: none;

    width: calc(100% - 4rem);
    justify-content: center;
    gap: 3rem;
    padding: 0 1rem;

    height: 0;
    min-height: 0;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
    &.filtersExpanded {
        height: 5rem;
        min-height: 5rem;
    }

    .positionButtons {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;

        button {
            background-color: unset;
            border: .5rem solid var(--theme-primary-text);
            color: var(--theme-primary-text);
            font-family: $header-font-family;
            font-size: 2rem;
            padding: .5rem;
            transform: skew(-$skew);
            transition: all .2s ease-in-out;

            cursor: pointer;

            &:hover:before {
                content: '';
                background-color: rgba(255,255,255,0.2);
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
            }

            &:first-child{
                border-top-left-radius: 1rem;
                border-bottom-left-radius: 1rem;
            }

            &:last-child{
                border-top-right-radius: 1rem;
                border-bottom-right-radius: 1rem;
            }

            @each $position, $color in $position-colors {
                &.#{$position}.active {
                    background-color: $color;
                    color: var(--theme-input-bg);
                }
            }
        }

        .positionButtonText {
            transform: skew($skew)
        }
    }

    .sliderWrapper {
        flex-grow: 1;
        max-width: 47rem;
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

    .sliderHandle {
        margin-left: -2.5rem;
        margin-top: -2.5rem;
        width: 5rem;
        height: 5rem;

        white-space: nowrap;
        position: absolute;
        z-index: 2;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sliderHandleVisual {
        background-color: var(--theme-accent);
        height: 2rem;   
        width: 2rem;
        border-radius: 1rem;
        box-shadow: rgba(var(--theme-shade-dark), 0.65) 1px 1px 1px;
    }

    .sliderRail {
        background-color: var(--theme-contrast-bg);
        border-radius: .25rem;
        width: 100%;
        height: .5rem;
        position: absolute;
        top: -.25rem;
        pointer-events: none;
    }

    .sliderValue {
        color: var(--theme-contrast-text);
        font-size: 2rem;
        height: 100%;
        
        display: flex;
        justify-self: center;
        align-items: center;

        &Text{
            background-color: var(--theme-contrast-bg);
            border-radius: .75rem;
            min-width: 4rem;
            padding: .25rem;
            text-align: center;
        }
    }
}

.closeButton {
    background: unset;
    border: unset;
    color: var(--theme-input-text);
    display: none;
    font-size: 6rem;
    height: 4rem;
    line-height: 4rem;
    cursor: pointer;
    padding: 0 1rem;

    position: absolute;
    top: 2rem;
    right: 1rem;

}

.playerList {
    max-height: calc(100% - 7rem);
    margin: 1rem 1rem 2rem;
    overflow: auto;
    width: 100%;
}