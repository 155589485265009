@import 'styles/variables.scss';

$trade-evaluator-height: 34rem;

.tradeEvaluatorCard {
    background-color: var(--theme-team-1-bg);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: 0;

    &.expandTeam1:before {
        top: 100%;
        transition-delay: unset;
    }

    &.expandTeam2:before {
        top: 0;
        transition-delay: unset;
    }
}

.team1Players, .team2Players {
    height: 50%;
    overflow: hidden;
    transition: height .25s ease-in-out, flex-grow .25s ease-in-out;
    transition-delay: .2s;

    &.collapsed {
        height: 0;
        flex-grow: 0;
        transition-delay: unset;
    }
}

.team1Players {
    margin-top: 8.5rem;
}

.teamLists {
    background: linear-gradient(180deg, var(--theme-team-1-bg) 0%, var(--theme-team-2-bg) 100%);
    height: calc(100% - #{$trade-evaluator-height});
    display: flex;
    flex-direction: column;

}

.tradeEvaluator {
    height: $trade-evaluator-height;
    background-color: var(--theme-team-2-bg);
    position: relative;
}

.tradeEvaluatorTitle {
    color: var(--theme-primary-text);
    font-size: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;

    position: absolute;
    top: 0;

    & > span {
        padding-bottom: 1rem;
        width: 17rem;
        border-bottom: 1px solid rgba(var(--theme-shade), 0.3);
        text-align: center;
    }
}

.tradeEvaluatorButtons {
    position: absolute;
    top: 0;
    right: 4rem;
    width: 100%;
    z-index: 1;

    display: flex;
    justify-content: flex-end;

    .flipButton > svg {
        padding: .5rem 0;
    }
}