@import 'styles/variables.scss';

.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    & > * {
        pointer-events: auto;
    }
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
}

.modalBody {
    position: relative;
}

.modalContent {
    background-color: white;
    border: 1px solid $color-gray-2;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    max-width: 95vw;
    position: relative;

    &.fullScreen {
        border: none;
        border-radius: unset;
    }

    .closeButton {
        background-color: unset;
        border: unset;
        color: $color-gray-6;
        font-size: 5rem;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        cursor:pointer;
    
        height: 8rem;
        width: 8rem;
        padding: 0;
    }

    .title {
        color: $color-gray-7;
        margin: 2rem 8rem;
        font-size: 5rem;
        height: 5rem;
        text-align: center;
        font-family: $header-font-family;
        letter-spacing: 1px;
    }

    &.linen {
        background-color: $color-brown-2;
        border-color: $color-brown-3;

        .closeButton {
            color: $color-brown-7;
        }

        .title {
            color: $color-brown-8;
        }
    }

    &.blue-5 {
        background-color: $color-blue-5;
        border-color: $color-blue-8;

        .closeButton, .title {
            color: $color-gray-1;
        }
    }

    &.blue-6 {
        background-color: $color-blue-6;
        border-color: $color-gray-1;

        .closeButton, .title {
            color: $color-gray-1;
        }
    }

    &.blue-7 {
        background-color: $color-blue-7;
        border-color: $color-gray-1;

        .closeButton, .title {
            color: $color-gray-1;
        }
    }

    &.blue-8 {
        background-color: $color-blue-8;
        border-color: $color-gray-1;

        .closeButton, .title {
            color: $color-gray-1;
        }
    }
}