@import 'styles/variables.scss';

.searchContainer {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.searchIcon {
    color: rgb(141, 141, 141);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.25rem;
    position: absolute;
    height: 100%;
    left: 0;
}

.search {
    border: 1px solid #ccc;
    border-radius: .5rem;
    color: var(--theme-input-text);
    flex-shrink: 1;
    font-size: 2.5rem;
    padding: 1rem .5rem;
    padding-left: 4rem;
    width: calc(100% - 4rem);

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
        display: none;
        -webkit-appearance: none;
    }
}

.clearButton {
    background: unset;
    border: unset;
    color: var(--theme-input-text);
    font-size: 4rem;
    padding: 0 1rem;
    pointer-events: none;
    opacity: 0;
    transition: all 0.15s ease-in-out;

    &.showClearButton {
        pointer-events: all;
        opacity: 1;
    }
}