@import 'styles/variables.scss';

.button {
    background-color: unset;
    color: var(--theme-primary-text);
    cursor: pointer;
    font-family: $header-font-family;
    border-radius: .5rem;
    font-size: 2.5rem;
    padding: .5rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    &.inactive {
        box-shadow: unset;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &.large {
        font-size: 3rem;
    }

    &:hover:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: linear-gradient(90deg, rgba(var(--theme-shade),0) 0%, rgba(var(--theme-shade),0.1) 5%, rgba(var(--theme-shade),0.1) 95%, rgba(var(--theme-shade),0) 100%);
    }

    &:active {
        box-shadow: rgba(0, 0, 0, 0.2) 0 .1rem .2rem, rgba(0, 0, 0, 0.2) 0 .1rem .1rem;
    }
}

$types: (
    "menu": $theme-menu,
    "transluscent": rgba(var(--theme-shade),0.3),
    "dark-transluscent": rgba(0,0,0,0.3),
    "outline": rgba(var(--theme-shade),1),
);

$button-types: map-merge($types, $position-colors);

@each $type, $color in $button-types {
    .#{$type} {
        border: 1px solid $color;
        color: $color;
        > * {
            stroke: $color;
        }

        &.inactive {
            background-color: $color-gray-3;
            border-color: $color-gray-5;
            color: black;
        }

        &.solid {
            background-color: $color;
            border: unset;
            color: var(--theme-primary-text);
            > * {
                stroke: var(--theme-primary-text);
            }

            &.inactive {
                background-color: $color-gray-5;
                color: black;
            }
        }
    }
}