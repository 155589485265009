@import 'styles/variables.scss';

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    font-family: $logo-font-family;
    white-space: nowrap;

    .firstWord {
        color: white;
        padding: .5rem .75rem;
        position: relative;
        background-color: $logo-color;
    }

    .secondWord {
        color: $logo-color;

    }
}