.backdrop {
    background-color: white;
    border-radius: 1rem;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingEllipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loadingEllipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgb(118, 118, 118);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .loadingEllipsis div:nth-child(1) {
    left: 8px;
    animation: loadingEllipsis1 0.6s infinite;
  }
  .loadingEllipsis div:nth-child(2) {
    left: 8px;
    animation: loadingEllipsis2 0.6s infinite;
  }
  .loadingEllipsis div:nth-child(3) {
    left: 32px;
    animation: loadingEllipsis2 0.6s infinite;
  }
  .loadingEllipsis div:nth-child(4) {
    left: 56px;
    animation: loadingEllipsis3 0.6s infinite;
  }
  @keyframes loadingEllipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes loadingEllipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes loadingEllipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  