.helpInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    max-width: 150rem;
    padding: 0 2rem 2rem;

    h3 {
        font-size: 2.5rem;
        margin: 1rem 0;
    }

    p {
        font-size: 2rem;
        margin: 1rem 0;
    }

    ul {
        padding-left: 4rem;
    }
}