// IMPORTS
@import 'react-loading-skeleton/dist/skeleton.css';

// FONTS
@import url("https://fonts.googleapis.com/css?family=Oswald");
@import url("https://fonts.googleapis.com/css?family=Bebas+Neue");
@import url("https://fonts.googleapis.com/css?family=Righteous");
@import url("https://fonts.googleapis.com/css?family=Gabarito");

@font-face {
    font-family: 'Adjusted Impact Fallback';
    src: local(Impact);
    size-adjust: 82%;
    ascent-override: normal;
    descent-override: normal;
    line-gap-override: normal;
}

@font-face {
    font-family: 'Adjusted Arial Fallback';
    src: local(Arial);
    size-adjust: 103%;
    ascent-override: normal;
    descent-override: normal;
    line-gap-override: normal;
}

@font-face {
    font-family: 'Adjusted Arial Narrow Fallback';
    src: local(Arial Narrow);
    size-adjust: 97%;
    ascent-override: normal;
    descent-override: normal;
    line-gap-override: 37%;
}

$logo-font-family: 'Righteous', 'Adjusted Arial Fallback';
$header-font-family: 'Gabarito', 'Adjusted Arial Narrow Fallback';
$content-font-family: 'Gabarito', 'Adjusted Arial Narrow Fallback';

// COLORS

$theme-white: #f2f4f3;
$theme-brown: #ECD7C3;
$theme-football: #825736;
$theme-linen: #F0E6DD;
$theme-green-light: #E1FDF3;
$theme-green: #528066;
$theme-green-dark: #0A4021;
$theme-red: #a81211;
$theme-orange: #6F2006;
$theme-grey: #4e4e4e;
$theme-olive: #41693c;
$theme-purple: #5e2c8d;
$theme-menu: $theme-grey;
$theme-pink: #ef233c;

$logo-color: #DC8118;

$color-orange: #FCA311;
$color-green: #515A47;
$color-brown: #7A4419;
$color-celadon: #8DDCA4;

$color-gray-1: #E5E5E5;
$color-gray-2: #D6D6D6;
$color-gray-3: #B8B8B8;
$color-gray-4: #999999;
$color-gray-5: #858585;
$color-gray-6: #666666;
$color-gray-7: #474747;
$color-gray-8: #292929;

$color-blue-1: #D1DBF0;
$color-blue-2: #A3B6E1;
$color-blue-3: #7492D2;
$color-blue-4: #466EC3;
$color-blue-5: #32539A;
$color-blue-6: #233A6C;
$color-blue-7: #14213D;
$color-blue-8: #1A2338;

$color-graygreen-3: #C2C5AA;
$color-graygreen-4: #A4AC86;
$color-graygreen-5: #656D4A;
$color-graygreen-6: #414833;
$color-graygreen-7: #333D29;

$color-brown-2: #F0E6DD;
$color-brown-3: #E5D4C5;
$color-brown-4: #A68A64;
$color-brown-5: #936639;
$color-brown-6: #7F4F24;
$color-brown-7: #582F0E;
$color-brown-8: #352208;

$position-colors: (
    'rb': var(--theme-position-rb),
    'wr': var(--theme-position-wr),
    'qb': var(--theme-position-qb),
    'te': var(--theme-position-te),
);

.grabbable {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

// VALUES

$mobile-break-point: 1036px;
$page-width: 160rem;