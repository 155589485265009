@import 'styles/variables.scss';

.teamPlayers {
    color: var(--theme-primary-text);
    font-size: 2rem;
    height: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.background {
    background-color: rgba(var(--theme-shade-dark), 0.3);
    border-radius: 1rem;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: calc(100% - 8rem);
    height: calc(100% - 2rem);
}

.teamNameDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    font-size: 3rem;
    width: 17rem;
    border-bottom: 1px solid rgba(var(--theme-shade), 0.3);
}

.playerListWrapper {
    height: 100%;
    width: 100%;
    max-height: 30rem;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.playerList {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    height: calc(100% - 2rem);
    margin: 0 2rem;
    padding-top: .5rem;
    overflow: auto;
    width: 100%;
}

.emptyPlayerList {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 100%;

    > span {
        background-color: rgba(0, 0, 0, 0.2);
        color: $color-gray-1;
        font-family: $header-font-family;
        padding: 2rem;
    }
}

.emptyPlayerAddButtonText {
    font-size: 2.5rem;
    padding: 1.5rem;
}

.backButton {
    position: absolute;
    top: 0;
    left: 0;
    height: 4rem;
    width: 10rem;

    margin-top: 1.75rem;
    margin-left: 1.5rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    opacity: 0;
    transition: all .25s ease-in-out;
    pointer-events: none;

    &.editMode {
        opacity: 100;
        pointer-events: all;
    }
}

.backButtonText {
    margin-left: .5rem;
    padding: .5rem 0;
}

.teamEditWrapper {
    max-height: 0;
    opacity: 0;
    transition: all .25s ease-in-out;

    &.editMode {
        max-height: calc(100% - 38rem);
        opacity: 100;
    }
}