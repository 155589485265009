@import './variables.scss';

// Primary theme colors

:root {
    --theme-team-1-bg: #{$color-blue-6};
    --theme-team-2-bg: #{$color-blue-7};
    --theme-primary-text: #{$color-gray-1};
    --theme-secondary-text: #{$color-gray-2};
    --theme-contrast-bg: #{$color-gray-2};
    --theme-contrast-text: #{$color-gray-8};
    --theme-light-text: #{$color-gray-1};
    --theme-accent: #{$logo-color};
    --theme-shade: 255,255,255;
    --theme-shade-dark: 0,0,0;
    --theme-shade-light: 255,255,255;
    --theme-menu-bg: #{$color-brown-2};
    --theme-menu-text: #{$color-brown-8};
    --theme-menu-contrast-text: white;
    --theme-input-bg: #{$color-gray-8};
    --theme-input-text: #{$color-gray-1};

    --theme-position-rb: #e85d04;
    --theme-position-wr: #9d4edd;
    --theme-position-qb: #429213;
    --theme-position-te: #aa9f34;

    --positive-color: #{$color-graygreen-4};
    --negative-color: #{$theme-pink};

    &[data-theme="dark"] {
        --theme-team-1-bg: #{$color-gray-8};
        --theme-team-2-bg: black;
        --theme-primary-text: #{$color-gray-2};
        --theme-secondary-text: #{$color-gray-3};
        --theme-contrast-bg: #{$color-gray-2};
        --theme-contrast-text: #black;
        --theme-shade: 255,255,255;
    }

    &[data-theme="light"] {
        --theme-team-1-bg: #{$color-brown-2};
        --theme-team-2-bg: #{$color-brown-3};
        --theme-primary-text: #{$color-brown-8};
        --theme-secondary-text: #{$color-brown-7};
        --theme-contrast-bg: #{$color-brown-8};
        --theme-contrast-text: #{$color-brown-2};
        --theme-input-bg: #{$color-gray-1};
        --theme-input-text: #{$color-gray-8};
        --theme-shade: 46, 32, 20;
        --theme-shade-dark: 55, 55, 55;

        --theme-position-rb: #c5310c;
        --theme-position-wr: #9d4edd;
        --theme-position-qb: #316c0f;
        --theme-position-te: #7B7226;

        --positive-color: #{$theme-olive};
        --negative-color: #{$theme-red};
    }
}

@media screen and (min-width: $mobile-break-point) {
    html {
        font-size: 8px;
    }
}

@media screen and (max-width: calc(#{$mobile-break-point} - 1px)) {
    html {
        font-size: 6px;
        overscroll-behavior: none;
    }
}

body {
    background-color: $theme-white;
    margin: 0;
    font-family: $content-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:hover, a:focus, a:active {
    color: $theme-olive;
}

a {
    border-radius: .5rem;
    cursor: pointer;
}

a:hover {
    background-color: rgba(204, 204, 204, 0.325);
}