@import 'styles/variables.scss';

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    transition: all .2s ease-in-out;
}

.winner {
    color: var(--positive-color);
}

.loser {
    color: var(--negative-color);
}

.arrowUp {
    margin-left: .5rem;
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    
    border-bottom: 1.25rem solid var(--positive-color);

    &.small {
        border-left-width: .5rem;
        border-right-width: .5rem;
        border-bottom-width: .75rem;
    }
}


  
.arrowDown {
    margin-left: .5rem;
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    
    border-top: 1.25rem solid var(--negative-color);

    &.small {
        border-left-width: .5rem;
        border-right-width: .5rem;
        border-top-width: .75rem;
    }
}