@import 'styles/variables.scss';

.layout {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.main {
    background-color: #383e53;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    max-width: calc(100% - 15rem);
    overflow-y: auto;
    padding: 1rem 1rem 0;
    z-index: 1;
}

.layout {
    flex-direction: column;
}

.fade {
    display: none;
}

.main {
    padding: 0;
    max-width: 100%;
}