@import 'styles/variables.scss';

.tradeEvaluation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 2rem;
    position: relative;
    padding: 0 4rem;
    box-sizing: border-box;
}

.dividerBox {
    border: 1px solid var(--theme-primary-text);
    border-radius: 2px;
    color: var(--theme-primary-text);
    flex-basis: 30rem;
    max-width: 50rem;
    flex-grow: 2;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 3.25rem;
    padding: .5rem 1.5rem;
    left: 5%;
    width: 30rem;

    overflow: visible;

    .totalDiff {
        width: 10.5rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-right: 1px dotted var(--theme-secondary-text);
        padding-right: 1rem;
    }

    .evaluateDescription {
        font-size: 2rem;
        margin-left: 1.5rem;
        max-width: 80%;
    }
}

.dividerChart {
    width: 25rem;
    height: 1px;
    background-color: var(--theme-primary-text);
    position: relative;
}

.dividerExtension {
    flex: 1;
    width: 0;
    height: 1px;
    background-color: var(--theme-primary-text);
    position: relative;
}

.positionBars {
    width: 100%;
    height: 30rem;
    top: -15rem;
    position: absolute;

    display: flex;
    justify-content: center;
    gap: 1.5rem;
}