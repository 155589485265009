@import 'styles/variables.scss';

.diffBarWrapper {
    width: 2.5rem;
    height: 100%;
    position: relative;
}

.diffBar {
    width: 100%;
    background-color: var(--theme-primary-text);
    position: absolute;
}

.positionBarLabel {
    position: absolute;
    bottom: .5rem;
    color: var(--theme-primary-text);
    font-size: 2rem;
}

.positive {
    background: linear-gradient(var(--theme-primary-text) 0%, var(--theme-primary-text) 30%, transparent 100%);
    .positionBarLabel {
        bottom: calc(100% + 1rem);
        right: .25rem;
        transform: rotate(-22deg);
    }
}

.positive:before {
    content: '';
    width: 0; 
    height: 0; 
    border-left: 2.5rem solid transparent;

    display: flex;
    justify-content: center;
    align-items: center;
    
    border-bottom: 1rem solid var(--theme-primary-text);
    position: absolute;
    top: -1rem;
}

.negative {
    background: linear-gradient(0, var(--theme-primary-text) 0%, var(--theme-primary-text) 30%, transparent 100%);
    
    .positionBarLabel {
        bottom: unset;
        top: calc(100% + 1.25rem);
        right: .25rem;
        transform: rotate(22deg);
    }
}

.negative:before {
    content: '';
    width: 0; 
    height: 0; 
    border-left: 2.5rem solid transparent;

    display: flex;
    justify-content: center;
    align-items: center;
    
    border-top: 1rem solid var(--theme-primary-text);
    position: absolute;
    bottom: -1rem;
}

@each $position, $color in $position-colors {
    .#{$position}.diffBar {
        background-color: $color;
    }

    .#{$position}.diffBar.positive {
        background: linear-gradient($color 0%, $color 30%, transparent 100%);
    }

    .#{$position}.diffBar.negative {
        background: linear-gradient(0, $color 0%, $color 30%, transparent 100%);
    }

    .#{$position}.positive:before {
        border-bottom-color: $color;
    }

    .#{$position}.negative:before {
        border-top-color: $color;
    }
}